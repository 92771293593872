<template>
  <div class="form-request-block">
    <div class="form-request-inner">
      <div class="form-request-content">
        <div class="form-request-form">
          <div
            v-if="isName"
            class="input-wrapper input-wrapper-input"
          >
            <label for="name">{{ nameName }}</label>
            <input
              id="name"
              v-model="form.name"
              :placeholder="namePlaceholder"
              class="input"
            >
            <template
              v-if="$v.name"
            >
              <div
                v-for="(error, index) in $v.name.$errors"
                :key="index"
                class="error-valid"
              >
                {{ error.$message }}
              </div>
            </template>
          </div>

          <div
            v-if="isPhone"
            class="input-wrapper input-wrapper-input"
          >
            <label for="phone">{{ phoneName }}</label>
            <input
              id="phone"
              v-model="form.phone"
              :placeholder="phonePlaceholder"
              class="input"
            >
            <template
              v-if="$v.phone"
            >
              <div
                v-for="(error, index) in $v.phone.$errors"
                :key="index"
                class="error-valid"
              >
                {{ error.$message }}
              </div>
            </template>
          </div>

          <div
            v-if="isEmail"
            class="input-wrapper input-wrapper-input"
          >
            <label for="email">{{ emailName }}</label>
            <input
              id="email"
              v-model="form.email"
              :placeholder="emailPlaceholder"
              class="input"
            >
            <template
              v-if="$v.email"
            >
              <div
                v-for="(error, index) in $v.email.$errors"
                :key="index"
                class="error-valid"
              >
                {{ error.$message }}
              </div>
            </template>
          </div>

          <div
            v-if="isMessage"
            class="input-wrapper input-wrapper-textarea"
          >
            <label for="email">{{ messageName }}</label>
            <textarea
              id="message"
              v-model="form.message"
              :placeholder="messagePlaceholder"
              class="textarea"
            />
            <template
              v-if="$v.message"
            >
              <div
                v-for="(error, index) in $v.message.$errors"
                :key="index"
                class="error-valid"
              >
                {{ error.$message }}
              </div>
            </template>
          </div>
        </div>
        <div class="submit-group">
          <button
            class="btn primary form-request-submit-btn"
            :disabled="!isAgree || isLoadingSend"
            @click="submit"
          >
            {{ buttonText }}
          </button>
          <div class="privacy-link">
            Я согласен с <NuxtLink
              target="_blank"
              to="/politika-konfidencialnosti"
            >
              политикой конфиденциальности и использования запрашиваемых данных
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ValidationRuleWithParams } from '@vuelidate/core';
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';
import { errorParserServerResponse } from '~/utils';
import { useRoute } from '#app';
import { useServicePopup } from '~/composables/useServicePopup';

interface IForm {
  name?: string;
  phone?: string;
  email?: string;
  message?: string;
  file?: File | null;
}

type ISubmitFormBody = IForm & {
  type?: string;
  attachments?: File | null;
  nophone?: string;
  url?: string;
}

interface IFormRules {
  name?: {
    required?: ValidationRuleWithParams;
  };
  phone?: {
    required?: ValidationRuleWithParams;
  };
  email?: {
    required?: ValidationRuleWithParams;
    email: ValidationRuleWithParams;
  };
  message?: {
    required?: ValidationRuleWithParams;
  };
}

interface IFormRequestProps {
  name: string;
  label?: string;
  goal?: string;
  isName?: boolean;
  isNameRequired?: boolean;
  namePlaceholder?: string;
  isEmail?: boolean;
  isEmailRequired?: boolean;
  emailPlaceholder?: string;
  isPhone?: boolean;
  isPhoneRequired?: boolean;
  phonePlaceholder?: string;
  isMessage?: boolean;
  isMessageRequired?: boolean;
  messagePlaceholder?: string;
  isFile?: boolean;
  buttonText?: string;
  nameName?: string;
  phoneName?:string;
  messageName?:string;
  emailName?:string;
}

const {
  name,
  label = '',
  goal = '',
  isName = false,
  isNameRequired = false,
  namePlaceholder = 'Имя',
  isEmail = false,
  isEmailRequired = false,
  emailPlaceholder = 'Email',
  isPhone = false,
  isPhoneRequired = false,
  phonePlaceholder = '',
  isMessage = false,
  isMessageRequired = false,
  messagePlaceholder = 'Сообщение',
  isFile = false,
  buttonText = 'Отправить'
} = defineProps<IFormRequestProps>();

const {
  $toast, $api
} = useNuxtApp();

const form = reactive<IForm>({});
const isAgree = ref<boolean>(true);
const isLoadingSend = ref<boolean>(false);
const $route = useRoute();
const { onToggleSuccessSection, openServicePopup } = useServicePopup();

const rules = computed((): IFormRules => {
  const requiredObj: IFormRules = {};
  if (isName && isNameRequired) {
    requiredObj.name = {
      required: helpers.withMessage('Обязательно к заполнению', required)
    };
  }

  if (isPhone) {
    if (isPhoneRequired) {
      requiredObj.phone = {
        ...requiredObj.phone,
        required: helpers.withMessage('Обязательно к заполнению', required)
      };
    }
  }

  if (isEmail) {
    requiredObj.email = {
      email: helpers.withMessage('Неверный формат', email)
    };

    if (isEmailRequired) {
      requiredObj.email = {
        ...requiredObj.email,
        required: helpers.withMessage('Обязательно к заполнению', required)
      };
    }
  }

  if (isMessage && isMessageRequired) {
    requiredObj.message = {
      required: helpers.withMessage('Обязательно к заполнению', required)
    };
  }

  return requiredObj;
});

const initForm = () => {
  if (isName) {
    form.name = '';
  }

  if (isPhone) {
    form.phone = '';
  }

  if (isEmail) {
    form.email = '';
  }

  if (isMessage) {
    form.message = '';
  }

  if (isFile) {
    form.file = null;
  }
};

initForm();

const $v = useVuelidate(rules, form);

const onSuccessSubmit = () => {
  onToggleSuccessSection(true);
  openServicePopup();
};

const submit = async () => {
  const isFormCorrect = await $v.value.$validate();

  if (!isFormCorrect) {
    return false;
  }

  isLoadingSend.value = true;

  const body: ISubmitFormBody = {
    ...form,
    type: label || name
  };

  body.url = `${window.location.host}${$route.fullPath}`;

  const response = await $api.agent.post('/form/send', { body: { ...body } })
    .then((res) => res._data)
    .catch((err) => ({ error: errorParserServerResponse(err.response) }));

  if (response.error) {
    $toast.error(response.error);
    isLoadingSend.value = false;
    return false;
  }

  isLoadingSend.value = false;

  onSuccessSubmit();
};
</script>

<style lang="scss">

.form-request-form {
  display: flex;
  gap: 20px;
  margin: 32px 0;
  flex-wrap: wrap;
}

.input-wrapper {
  position: relative;

  & > label {
    color: var(--text-primary);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    margin-bottom: 14px;
    display: inline-block;
  }

  &.input-wrapper-textarea {
    flex: 1;
  }

  &.input-wrapper-input {
    max-width: 315px;
    width: 100%;
  }

  > .error-valid {
    position: absolute;
    left: 0;
    bottom: -15px;
  }
}

.submit-group {
  display: flex;
  gap: 36px;
  align-items: center;

  & > button {
    width: 144px;
    height: 46px;
    font-size: 20px;
    border-radius: 10px;
    background: #ABDD39;
    text-transform: uppercase;
    box-shadow: 0 4px 16px 0 rgb(55 77 8 / 25%), 0 3px 0 0 #799E27;

  }
}

.privacy-link {
  color: var(--light-gray);
  font-size: 13px;
  font-style: normal;
  line-height: 120%; /* 15.6px */
  letter-spacing: -0.13px;
  max-width: 254px;
  width: 100%;

  & > a {
    color: var(--text-black);
    font-size: 13px;
    font-style: normal;
    line-height: 120%;
    letter-spacing: -0.13px;
    text-decoration-line: underline;
  }
}

@media (max-width: 839px) {
  .form-request-form {
    flex-direction: column;
    gap: 28px;

    .input-wrapper-input {
      max-width: unset;
    }
  }
}

@media(max-width: 640px) {
  .submit-group {
    flex-direction: column;
    gap: 13px;

    & > button {
      width: 100%;
    }

    & > .privacy-link {
      text-align: center;
    }
  }

  .form-request-form {
    margin-bottom: 28px;
  }
}

</style>
